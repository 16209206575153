import * as React from "react"
import {useState, useEffect} from "react"
import { Helmet } from "react-helmet"
import i18n from '../components/i18n'
import { Trans, useTranslation } from 'react-i18next';
import Layout from "../components/layout"
import NavigationHelper from "../helpers/navigation"
import Seo from '../components/seo'
import Collapse from 'react-bootstrap/Collapse';

/** Images import */
import img_banner_header_bg from "../images/a1_cloud_vision_desktop_banner_bg.webp"
import img_layer780 from "../images/layer780.jpg"
import img_layer781 from "../images/layer781.jpg"
import img_layer782 from "../images/layer782.jpg"
import img_layer783 from "../images/layer783.jpg"
import img_layer784 from "../images/layer784.jpg"



// markup
const VisionPage = (props) => {
  
  const [activeLanguage, setActiveLanguage] = useState()
  useEffect(() => { setActiveLanguage(i18n.language); }, [setActiveLanguage])

  const { t } = useTranslation(['main']);

  // Navigation info
  const pageInfo = {
    key: "vision",
    lang: i18n.language
  }

  const collapseAllBut = function(OpenedLetter){
    if (OpenedLetter != "E")
      setOpenE(false)

    if (OpenedLetter != "P")
      setOpenP(false)

    if (OpenedLetter != "O")
      setOpenO(false)

    if (OpenedLetter != "C")
      setOpenC(false)

    if (OpenedLetter != "H")
      setOpenH(false)

    const timer = setTimeout(() => {
      switch(OpenedLetter){
        case 'E':
          setOpenE(true)
        break;
        case 'P':
          setOpenP(true)
        break;
        case 'O':
          setOpenO(true)
        break;
        case 'C':
          setOpenC(true)
        break;
        case 'H':
          setOpenH(true)
        break;
      }
    }, 500);

  }

  // Tabs
  const [openE, setOpenE] = useState(false);
  const [openP, setOpenP] = useState(false);
  const [openO, setOpenO] = useState(false);
  const [openC, setOpenC] = useState(false);
  const [openH, setOpenH] = useState(false);


  return (
    <Layout
      location={props.location}
      pageInfo={pageInfo}
    >
      <Helmet
        bodyAttributes={{
          class: 'vision-main-page'
      }}
      >
      </Helmet>

      <Seo
        description={t('main:vision-meta_seo-description_txt')}
        title={t('main:vision-meta_seo-title_txt' )}
        keywords={[
          t('main:vision-meta_seo-keyword1_txt'),
          t('main:vision-meta_seo-keyword2_txt'),
          t('main:vision-meta_seo-keyword3_txt'),
          t('main:vision-meta_seo-keyword4_txt'),
          t('main:vision-meta_seo-keyword5_txt'),
          t('main:vision-meta_seo-keyword6_txt'),
        ]}
        url={props.location.href}
        image_url={`${props.location.origin}/a1_cloud_logo.png`}
      />

      <div className="main-content">
  {/* breadcumbs */}
  <div className="site-breadcumbs">
    <div className="container">
      <small>
        {" "}
        <a href={NavigationHelper.useGeneratePageURL(activeLanguage, 'home')} title={t('main:global_header_home_menu-item-seo-title_txt')}>A1: </a>
        // {t('main:vision_header_breadcrumb_title')}
      </small>
    </div>
  </div>
  {/* end breadcumbs */}
  <div className="vishion-page-banner">
    <div
      className="service_header"
      style={{ backgroundImage: `url(${img_banner_header_bg})` }}
    >
      <div className="container">
        <div className="title_block">
          <h1 className="nos-service">
            <span>{t('main:vision_header_epoch_title')}</span> {t('main:vision_header_page_title')}
          </h1>
        </div>
        <div className="row">
          <div className="col-md-7"></div>
          <div className="col-md-5">
            <div className="block_text">
              <h2 className="h2_text">
                <Trans i18nKey="main:vision_main_catch-phrase_html">
                <strong>C'est à vos côtés</strong><br/><strong>que notre mission</strong><br/><strong>prend tout son sens !</strong>
                </Trans>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* service single header end  */}
  {/* middle section  */}
  <div className="service_detail vishion-page-middle">
    <div className="container">
      <div className="middle_content">
        <h2>
          <span>{t('main:vision_main_epoch_title')}</span> {t('main:vision_main_page_title')}
        </h2>
        <p>
        <Trans i18nKey="main:vision_main_mission_p1_html">
        La mission d'A1 Cloud Technologies est d'aider les organisations de ses
        clients et partenaires à gravir des sommets, les guider en mettant à
        leur disposition les ressources humaines et technologiques qui favorisent
        et accélèrent leur succès.
        </Trans>
        </p>
        <p>
        <Trans i18nKey="main:vision_main_mindset_p1_html">
        Parce que nous pensons que notre organisation, nos actions et nos résultats doivent refléter ce que nous croyons et qui nous sommes.
        </Trans>
        </p>

        <p>
        <Trans i18nKey="main:vision_main_mindset_p2_html">
        Qu'il est important de partager notre vision au sein de notre entreprise pour favoriser la cohésion et l'alignement.
        </Trans>
        </p>

        <p>
        <Trans i18nKey="main:vision_main_mindset_p3_html">
        Nous faisons le choix d'incarner les valeurs suivantes dans nos prises de positions, de décisions, de risques, d'initiatives et de parole.
        </Trans>
        </p>

        <p>
        <Trans i18nKey="main:vision_main_summary_html">
        L'essence de nos valeurs peut se résumer dans le mot <strong>EPOCH</strong>:
        </Trans>
        </p>
        <ul>
          <li>
            <Trans i18nKey="main:vision_explanation_letter_1_html">
              <strong>E</strong> comme empathie et éthique
            </Trans>
          </li>
          <li>
            <Trans i18nKey="main:vision_explanation_letter_2_html">
              <strong>P</strong> pour passion
            </Trans>
          </li>
          <li>
            <Trans i18nKey="main:vision_explanation_letter_3_html">
              <strong>O</strong> comme ouverture et originalité
            </Trans>
          </li>
          <li>
            <Trans i18nKey="main:vision_explanation_letter_4_html">
            <strong>C</strong> pour courage
            </Trans>
          </li>
          <li>
            <Trans i18nKey="main:vision_explanation_letter_5_html">
            <strong>H</strong> pour humilité
            </Trans>
          </li>
        </ul>
      </div>
    </div>

    <div className="vishon-page-box">
      <div className="container">
        <div
          className="panel-group vishon-page-box-grid tabsup_view"
          id="accordion"
        >
          <div className="panel panel-default">
            <div className="panel-heading ">
              <h2 className="panel-title">
                <a
                  dimension="width"
                  timeout={1000}
                  onClick={() => collapseAllBut('E')}
                  aria-controls="collapse1"
                  // title={t('main:vision_main_E_txt')} // Bug of translated string not showing found
                  aria-expanded={openE}
                >
                  <span>E</span>
                  <img src={img_layer780} />
                </a>
              </h2>
            </div>
            <Collapse in={openE}>
            <div id="collapse1">
              <div className="panel-body">
                <h4>{t('main:vision_widget_e_title')}</h4>
                <Trans i18nKey="main:vision_widget_e_html">
                <p>L'empathie nous permet de comprendre les autres comme nous même.<br/>Elle est la faculté de voir le monde avec les yeux d'un autre.<br/>Une qualité essentielle pour <strong>trouver des solutions techniques ou organisationnelles</strong> et qui favorisent les <strong>accords gagnant-gagnant</strong>.</p><p>L'empathie, associée à l'<strong>écoute</strong>, nous permet d'épouser la <strong>vision</strong> de nos partenaires et clients.</p><p>Dans cette <strong>époque cruciale</strong> pour l'avenir de la Planète, l'éthique nous sert de nord magnétique pour <strong>guider nos décisions</strong>, faire des arbitrages, établir de nouvelles relations, les entretenir ou y mettre un terme.</p>
                </Trans>
              </div>
            </div>
            </Collapse>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading ">
              <h2 className="panel-title">
                <a
                  dimension="width"
                  timeout={1000}
                  onClick={() => collapseAllBut('P')}
                  aria-controls="collapse2"
                  // title={t('main:vision_main_P_txt')}
                  aria-expanded={openP}
                >
                  <span>P</span>
                  <img src={img_layer781} />
                </a>
              </h2>
            </div>
            <Collapse in={openP}>
            <div id="collapse2">
              <div className="panel-body">
                <h4>{t('main:vision_widget_p_title')}</h4>
                <Trans i18nKey="main:vision_widget_p_html">
                <p>La matière que nous travaillons est <strong>la technologie de l'information.</strong> C'est une matière fascinante, en perpétuelle évolution, que nous voulons à la fois appréhender et façonner, mais elle requiert de la passion. C'est le prix à payer pour être à jour, à la pointe, ou tout simplement pertinent.</p><p>La passion, combinée à l'humilité et au courage nous permet, à chacun, de progresser jour après jour <strong>vers la maîtrise et l'excellence.</strong></p><p>Puisque c'est notre passion, il est naturel de la <strong>partager.</strong> C'est pourquoi nous la mettons au service d'autres organisations qu'elles soient clientes ou partenaires.</p>
                </Trans>
              </div>
            </div>
          </Collapse>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading ">
              <h2 className="panel-title">
                <a
                  dimension="width"
                  timeout={1000}
                  onClick={() => collapseAllBut('O')}
                  aria-controls="collapse3"
                  // title={t('main:vision_main_O_txt')}
                  aria-expanded={openO}
                >
                  <span>O</span>
                  <img src={img_layer782} />
                </a>
              </h2>
            </div>
            <Collapse in={openO}>
            <div id="collapse3">
              <div className="panel-body">
                <h4>{t('main:vision_widget_o_title')}</h4>
                <Trans i18nKey="main:vision_widget_o_html">
                <p>Elle peut être liée à l'esprit, aux autres, à la technologie ou au marché. L'ouverture est garante de <strong>nouveauté</strong> dans le sens où elle permet d'exposer comme d'apporter de nouvelles idées, concepts, relations, produits et services, là où ils produisent de la <strong>valeur</strong>, là où ils revêtent un sens nouveau.</p><p>Associée à l'imagination et l'empathie, l'ouverture <strong>source de partage, d'alignement et d'ingéniosité</strong>.</p>
                </Trans>
              </div>
            </div>
            </Collapse>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading ">
              <h2 className="panel-title">
                <a
                  dimension="width"
                  timeout={1000}
                  onClick={() => collapseAllBut('C')}
                  aria-controls="collapse4"
                  aria-expanded={openC}
                >
                  <span>C</span>
                  <img src={img_layer783} />
                </a>
              </h2>
            </div>
            <Collapse in={openC}>
            <div id="collapse4">
              <div className="panel-body">
                <h4>{t('main:vision_widget_c_title')}</h4>
                <Trans i18nKey="main:vision_widget_c_html">
                <p>Sans doute notre valeur numéro 1: condition <i>sine qua none</i> au <strong>progrès</strong>, elle permet de relever toutes sortes de <strong>défis</strong>, de trouver des <strong>solutions créatives</strong> qui nous permettent de <strong>réaliser notre vision</strong> tout en restant compétitifs, authentiques et intègres.</p>
                </Trans>
              </div>
            </div>
            </Collapse>
          </div>
          <div className="panel panel-default">
            <div className="panel-heading ">
              <h2 className="panel-title">
                <a
                  dimension="width"
                  timeout={1000}
                  onClick={() => collapseAllBut('H')}
                  aria-controls="collapse5"
                  // title={t('main:vision_main_H_txt')}
                  aria-expanded={openH}
                >
                  <span>H</span>
                  <img src={img_layer784} />
                </a>
              </h2>
            </div>
            <Collapse in={openH}>
            <div id="collapse5">
              <div className="panel-body">
                <h4>{t('main:vision_widget_h_title')}</h4>
                <Trans i18nKey="main:vision_widget_h_html">
                <p>Devenir et rester ou <strong>être de plus en plus en plus performant</strong> nécessitent courage et humilité. C'est l'humilité qui nous inscrit dans une démarche d'<strong>amélioration continue</strong>, c'est aussi elle qui nous pousse à  toujours donner le meilleur de nous même.</p><p>Encore et toujours, c'est l'humilité qui nous ordonne de <strong>nous entourer de personnes qui ont forcément quelque chose à nous apprendre</strong>.</p><p>Pour terminer, l'humilité nous pousse à <strong>nous remettre en question</strong>, nos valeurs, nos choix, notre intégrité, notre organisation et même nos compétences.</p>
                </Trans>
              </div>
            </div>
            </Collapse>
          </div>
        </div>
        <div className="tabsdown_view">
          {/* Nav pills */}
          <ul className="nav nav-tabs" role="tablist">
            <li className={ openE ? "nav-item epoch_block active" : "nav-item epoch_block" } >
              <a
                className="panel-title nav-link"
                data-toggle="pill"
                href="#vision_epoch_e"
                onClick={() => collapseAllBut('E')}
              >
                <span>E</span> <img src={img_layer780} />
              </a>{" "}
            </li>
            <li className={ openP ? "nav-item epoch_block active" : "nav-item epoch_block" }>
              <a
                className="panel-title nav-link"
                data-toggle="pill"
                href="#vision_epoch_p"
                onClick={() => collapseAllBut('P')}
              >
                <span>P</span> <img src={img_layer781} />
              </a>
            </li>
            <li className={ openO ? "nav-item epoch_block active" : "nav-item epoch_block" }>
              <a
                className="panel-title nav-link"
                href="#vision_epoch_o"
                data-toggle="pill"
                onClick={() => collapseAllBut('O')}
              >
                <span>O</span> <img src={img_layer782} />
              </a>
            </li>
            <li className={ openC ? "nav-item epoch_block active" : "nav-item epoch_block" }>
              <a
                className="panel-title nav-link"
                onClick={() => collapseAllBut('C')}
                data-toggle="pill"
                href="#vision_epoch_c"
              >
                <span>C</span> <img src={img_layer783} />
              </a>
            </li>
            <li className={ openH ? "nav-item epoch_block active" : "nav-item epoch_block" }>
              <a
                className="panel-title nav-link"
                onClick={() => collapseAllBut('H')}
                data-toggle="pill"
                href="#vision_epoch_h"
              >
                <span>H</span> <img src={img_layer784} />
              </a>
            </li>
          </ul>
          {/* Tab panes */}
          <div className="tab-content">
            <div
              className={ openE ? "panel-body tab-pane fade active" : "panel-body tab-pane fade" }
              id="vision_epoch_e"
              role="tabpanel"
              aria-labelledby="vision_epoch_e"
            >
              <h4>{t('main:vision_widget_e_title')}</h4>
              <Trans i18nKey="main:vision_widget_e_html">
                <p>L'empathie nous permet de comprendre les autres comme nous même.<br/>Elle est la faculté de voir le monde avec les yeux d'un autre.<br/>Une qualité essentielle pour <strong>trouver des solutions techniques ou organisationnelles</strong> et qui favorisent les <strong>accords gagnant-gagnant</strong>.</p><p>L'empathie, associée à l'<strong>écoute</strong>, nous permet d'épouser la <strong>vision</strong> de nos partenaires et clients.</p><p>Dans cette <strong>époque cruciale</strong> pour l'avenir de la Planète, l'éthique nous sert de nord magnétique pour <strong>guider nos décisions</strong>, faire des arbitrages, établir de nouvelles relations, les entretenir ou y mettre un terme.</p>
              </Trans>
            </div>
            <div
              className={ openP ? "panel-body tab-pane fade active" : "panel-body tab-pane fade" }
              id="vision_epoch_p"
              role="tabpanel"
              aria-labelledby="vision_epoch_p"
            >
              <h4>{t('main:vision_widget_p_title')}</h4>
              <Trans i18nKey="main:vision_widget_p_html">
                <p>La matière que nous travaillons est <strong>la technologie de l'information.</strong> C'est une matière fascinante, en perpétuelle évolution, que nous voulons à la fois appréhender et façonner, mais elle requiert de la passion. C'est le prix à payer pour être à jour, à la pointe, ou tout simplement pertinent.</p><p>La passion, combinée à l'humilité et au courage nous permet, à chacun, de progresser jour après jour <strong>vers la maîtrise et l'excellence.</strong></p><p>Puisque c'est notre passion, il est naturel de la <strong>partager.</strong> C'est pourquoi nous la mettons au service d'autres organisations qu'elles soient clientes ou partenaires.</p>
              </Trans>
            </div>
            <div
              className={ openO ? "panel-body tab-pane fade active" : "panel-body tab-pane fade" }
              id="vision_epoch_o"
              role="tabpanel"
              aria-labelledby="vision_epoch_o"
            >
              <h4>{t('main:vision_widget_o_title')}</h4>
              <Trans i18nKey="main:vision_widget_o_html">
                <p>Elle peut être liée à l'esprit, aux autres, à la technologie ou au marché. L'ouverture est garante de <strong>nouveauté</strong> dans le sens où elle permet d'exposer comme d'apporter de nouvelles idées, concepts, relations, produits et services, là où ils produisent de la <strong>valeur</strong>, là où ils revêtent un sens nouveau.</p><p>Associée à l'imagination et l'empathie, l'ouverture <strong>source de partage, d'alignement et d'ingéniosité</strong>.</p>
              </Trans>
            </div>
            <div
              className={ openC ? "panel-body tab-pane fade active" : "panel-body tab-pane fade" }
              id="vision_epoch_c"
              role="tabpanel"
              aria-labelledby="vision_epoch_c"
            >
              <h4>{t('main:vision_widget_c_title')}</h4>
              <Trans i18nKey="main:vision_widget_c_html">
                <p>Sans doute notre valeur numéro 1: condition <i>sine qua none</i> au <strong>progrès</strong>, elle permet de relever toutes sortes de <strong>défis</strong>, de trouver des <strong>solutions créatives</strong> qui nous permettent de <strong>réaliser notre vision</strong> tout en restant compétitifs, authentiques et intègres.</p>
              </Trans>
            </div>
            <div
              className={ openH ? "panel-body tab-pane fade active" : "panel-body tab-pane fade" }
              id="vision_epoch_h"
              role="tabpanel"
              aria-labelledby="vision_epoch_h"
            >
              <h4>{t('main:vision_widget_h_title')}</h4>
              <Trans i18nKey="main:vision_widget_h_html">
                <p>Devenir et rester ou <strong>être de plus en plus en plus performant</strong> nécessitent courage et humilité. C'est l'humilité qui nous inscrit dans une démarche d'<strong>amélioration continue</strong>, c'est aussi elle qui nous pousse à  toujours donner le meilleur de nous même.</p><p>Encore et toujours, c'est l'humilité qui nous ordonne de <strong>nous entourer de personnes qui ont forcément quelque chose à nous apprendre</strong>.</p><p>Pour terminer, l'humilité nous pousse à <strong>nous remettre en question</strong>, nos valeurs, nos choix, notre intégrité, notre organisation et même nos compétences.</p>
              </Trans>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* end vision page */}
  {/* <div className="nos_public vishion-page-public">
    <div className="container">
      <div className="row">
        <div className="col-sm-6">
          <div className="img-block">
            <img src={img_publications_a1_books} alt="books-A" />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="content_block">
            <h2>
              Etiam porta sem malesuada <br />
              magna mollis euismod
            </h2>
            <div className="ipade_up">
              <p>
                Nullam id dolor id nibh ultricies
                <br /> vehicula ut id elit. Fusce dapibus,
                <br /> tellus ac cursus commodo.
              </p>
            </div>
            <a href="#" className="btn btn-blue" title="info tip">
              Feuilleter nos publications
            </a>
          </div>
        </div>
      </div>
    </div>
  </div> */}
</div>

  {/* main content end */}
    </Layout>
  )
}

export default VisionPage
